'use client';

import classNames from 'classnames';
import { NumberedList } from '@sapientpro/sapientpro-data-models';
import AnimatedTitle from '../../../components/AnimatedTitle';
import ReasonsToOutsourceItem from './ReasonsToOutsourceItem';
import styles from './ReasonsToOutsourceSection.module.scss';

type ReasonsToOutsourceSectionProps = {
  data: NumberedList,
};

export default function ReasonsToOutsourceSection({ data }: ReasonsToOutsourceSectionProps) {
  return (
    <section className={classNames(styles.reasonsToOutsourceSection, 'container')}>
      <h2 className={styles.reasonsToOutsourceSection__title}>
        <AnimatedTitle title={data.title} />
      </h2>
      <div>
        {data.points.map((reason, index) => (
          <ReasonsToOutsourceItem
            key={reason.id}
            number={String(index + 1).padStart(2, '0')}
            title={reason.title}
            description={reason.description}
          />
        ))}
      </div>
    </section>
  );
}
