'use client';

import { useState } from 'react';
// eslint-disable-next-line import/no-absolute-path
import PlayButton from '/public/media/play.svg'; // need to import like that because svg gradient not working in other cases

import styles from './Article.module.scss';

type ArticleVideoProps = {
  videoId: string,
  videoDescription?: string,
};

const ArticleVideo = ({ videoId, videoDescription }: ArticleVideoProps) => {
  const [isVideoOn, setIsVideoOn] = useState(false);

  return (
    <div className={styles.videoContainer}>
      {isVideoOn ? (
        <div className={styles.videoWrap}>
          <iframe
            src={`https://www.youtube.com/embed/${videoId}?rel=0&enablejsapi=1&autoplay=1`}
            title='Video in the article'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          />
        </div>
      ) : (
        <div className={styles.videoWrap}>
          <button
            type='button'
            className={styles.video__controlButton}
            onClick={() => setIsVideoOn(true)}
          >
            <PlayButton />
          </button>
          <div className={styles.video}>
            <img
              src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
              alt='Video preview'
              className={styles.video__thumbnail}
            />
          </div>
        </div>
      )}
      {videoDescription && (
      <div className={styles.videoDescription}>
        <svg className={styles.videoDescription__icon}>
          <use
            xlinkHref='/media/smallPlay.svg#smallPlaySVG'
            href='/media/smallPlay.svg#smallPlaySVG'
          />
        </svg>
        <p className={styles.videoDescription__text}>{videoDescription}</p>
      </div>
      )}
    </div>
  );
};

export default ArticleVideo;
