'use client';

import { useState } from 'react';
import classNames from 'classnames';
import { TitleDescription } from '@sapientpro/sapientpro-data-models';
import styles from './CaseRevealingPoints.module.scss';

const CaseRevealingPointItem = ({ data }: { data: TitleDescription }) => {
  const [showText, setShowText] = useState<boolean>(false);

  return (
    <button
      type='button'
      onClick={() => setShowText(!showText)}
      className={classNames(styles.revealingPoint, {
        [styles.active]: showText,
      })}
    >
      <div className={styles.pointContent}>
        <h5 className={styles.pointTitle}>{data.title}</h5>
        <p className={styles.pointText}>{data.description}</p>
      </div>
      <svg className={styles.pointIcon}>
        <use
          xlinkHref='/media/arrow-right-bold.svg#arrowRight'
          href='/media/arrow-right-bold.svg#arrowRight'
        />
      </svg>
    </button>
  );
};

export default CaseRevealingPointItem;
