'use client';

import classNames from 'classnames';
import { ServicesTilesComponent } from '@sapientpro/sapientpro-data-models';
import styles from './DevelopmentServicesSection.module.scss';
import AnimatedTitle from '../../../components/AnimatedTitle';
import DevelopmentServicesCard from './DevelopmentServicesCard';

type DevelopmentServicesSectionProps = {
  data: ServicesTilesComponent;
};

export default function DevelopmentServicesSection({ data }: DevelopmentServicesSectionProps) {
  return (
    <section className={classNames(styles.developmentServicesSection, 'container')}>
      <h2 className={styles.developmentServicesSection__title}>
        <AnimatedTitle title={data.title} />
      </h2>
      <div className={styles.developmentServicesSection__cards}>
        {data.items.map(item => (
          <DevelopmentServicesCard
            key={item.id}
            icon={item.image.url}
            title={item.title}
            description={item.description}
          />
        ))}
      </div>
    </section>
  );
}
