'use client';

import React from 'react';
import { IFile } from '@sapientpro/sapientpro-data-models';
import { useAtomValue } from 'jotai';
import styles from './IndustryCard.module.scss';
import { Theme, theme } from '../../../store/theme';
import Img from '../../../components/Img';

export type Industry = {
  id: number;
  title: string;
  lightImage: IFile;
  darkImage: IFile;
};

type IndustryCardProps = {
  industry: Industry;
};

const IndustryCard = ({ industry }: IndustryCardProps) => {
  const appTheme = useAtomValue<Theme>(theme);

  const image = appTheme === 'light' ? industry.lightImage : industry.darkImage;

  return (
    <div className={styles.industry}>
      <div className={styles.imageWrapper}>
        <Img
          src={image.url}
          alt={image.alternativeText || 'industry'}
          className={styles.image}
          key={appTheme}
        />
      </div>
      <p className={styles.title}>{industry.title}</p>
    </div>
  );
};

export default IndustryCard;
