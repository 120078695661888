'use client';

import { RichText } from '@sapientpro/sapientpro-data-models';
import classNames from 'classnames';
import styles from './RichTextSection.module.scss';
import Paragraph from '../../page-components/Blog/Article/Paragraph';

const RichTextSection = ({ data }: { data: RichText }) => (
  <section className={classNames(styles.richTextSection, 'container')}>
    <Paragraph value={data.description} />
  </section>
);

export default RichTextSection;
