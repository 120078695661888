'use client';

import classNames from 'classnames';
import { IFile } from '@sapientpro/sapientpro-data-models';
import styles from './CustomProcessCardSliderSection.module.scss';
import Slider from '../../../components/Slider/Slider';
import AnimatedTitle from '../../../components/AnimatedTitle';
import CustomProcessCard from './CustomProcessCard';

type CustomProcessCardSliderSectionProps = {
  data: {
    id: number,
    title: string,
    description: string,
    items: {
      id: number,
      title: string,
      description: string,
      video: IFile,
      previewImage: {
        id: number,
        caption: string | null,
        alternativeText: string | null,
        url: string,
        ext: string,
        width: number,
        height: number,
      },
    }[],
  },
};

export default function CustomProcessCardSliderSection({
  data,
}: CustomProcessCardSliderSectionProps) {
  return (
    <section className={classNames(styles.customProcessSection, 'container')}>
      <div className={styles.customProcessSection__titleAndDescriptionPart}>
        <h2 className={styles.customProcessSection__titleAndDescriptionPart__title}>
          <AnimatedTitle title={data.title} />
        </h2>
        <p className={styles.customProcessSection__titleAndDescriptionPart__description}>
          {data.description}
        </p>
      </div>
      <Slider
        options={{
          slideFocus: 0,
          slideStart: 0,
          slidesPerPage: 1,
          slidesGap: 96,
          showOverflow: true,
          showPagination: true,
          showArrows: true,
        }}
        slides={data.items.map((item, index) => ({
          id: item.id,
          content: (
            <CustomProcessCard
              stepNumber={index + 1}
              title={item.title}
              description={item.description}
              video={{
                title: item.title,
                clip: item.video,
                preview: {
                  url: item.previewImage.url,
                  alternativeText: item.previewImage.alternativeText || 'Process card video preview',
                },
              }}
            />),
        }))}
      />
    </section>
  );
}
