'use client';

import classNames from 'classnames';
import { ExpandedRevealingPoints } from '@sapientpro/sapientpro-data-models';
import styles from './SideRevealingPointsSection.module.scss';
import AnimatedTitle from '../../../components/AnimatedTitle';
import SideRevealingPoints from './SideRevealingPoints';

type SideRevealingPointsSectionProps = {
  data: ExpandedRevealingPoints;
};

export default function SideRevealingPointsSection({
  data,
}: SideRevealingPointsSectionProps) {
  return (
    <section className={classNames(styles.developmentSolutionsSection, 'container')}>
      <h2 className={styles.developmentSolutionsSection__title}>
        <AnimatedTitle title={data.title} />
      </h2>
      <SideRevealingPoints
        points={data.points}
      />
    </section>
  );
}
