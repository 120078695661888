'use client';

import { IImageSvg, ITechStack, ITechStackComponent } from '@sapientpro/sapientpro-data-models';
import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import AnimatedTitle from '../../../components/AnimatedTitle';
import Img from '../../../components/Img';
import activeHomeSection, { Section } from '../../../store/activeHomeSection';
import deviceScreenSize, { ScreenSize } from '../../../store/deviceScreenSize';
import { theme } from '../../../store/theme';
import homepageStyles from '../Home.module.scss';

import styles from './TechStack.module.scss';
import SvgWithCurrentColorChange from '../../../components/SvgWithCurrentColorChange';

type TechStackProps = {
  data: ITechStackComponent,
  // contentRef: React.RefObject<HTMLDivElement>,
  id: string,
  contentId: string,
  className?: string,
  type?: 'homepage',
};

const TechStackColumn = ({
  techStack,
  handleColumnOpen,
  isNoActive,
  isActive,
}: {
  techStack: ITechStack;
  handleColumnOpen: (id: number) => void;
  isNoActive: boolean;
  isActive: boolean;
}) => {
  const deviceSize = useAtomValue(deviceScreenSize);
  const appTheme = useAtomValue(theme);

  const toggleDropdown = () => {
    if (deviceSize <= ScreenSize.TABLET_PORTRAIT) {
      handleColumnOpen(techStack.id);
    }
  };

  return (
    <div
      className={styles.column}
      key={techStack.id}
    >
      {deviceSize > ScreenSize.TABLET_PORTRAIT
        ? <h3 className={styles.column__title}>{techStack.techStack}</h3>
        : (
          <button
            type='button'
            onClick={toggleDropdown}
            className={classNames(styles.column__title, {
              [styles.active]: isActive,
              [styles.allNotActive]: isNoActive,
            })}
          >
            {techStack.techStack}
            <svg className={styles.dropdownIcon}>
              <use
                xlinkHref='/media/angle_down.svg#angleDown'
                href='/media/angle_down.svg#angleDown'
              />
            </svg>
          </button>
        )}
      <div className={styles.column__content}>
        <div className={classNames(styles.column__technologies, {
          [styles.active]: isActive,
        })}
        >
          {techStack?.technologies?.map(technology => (
            <div
              className={styles.item}
              key={technology.id}
            >
              <div className={styles.item__image}>
                <img
                  height={32}
                  width={32}
                  src={technology?.image?.[appTheme]?.url}
                  alt={technology?.image?.[appTheme]?.alternativeText || 'technology image'}
                  loading='lazy'
                />
              </div>
              <div className={styles.item__titleWrap}>
                <p className={styles.item__title}>{technology?.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

type TabItem = {
  id: number;
  name: string;
  image: IImageSvg;
  description: string | null;
};

const TechStack = ({
  data, contentId, className, type, id,
}: TechStackProps) => {
  const [isAnimationStarted, setIsAnimationStarted] = useState<boolean>(false);
  const [techStackImageContainerOffsetTop, setTechStackImageContainerOffsetTop] = useState<number>(0);
  const [activeColumns, setActiveColumns] = useState<number[]>([data?.tech_stacks?.[0]?.id]);
  const [isNoActive, setIsNoActive] = useState<boolean>(false);
  const activeSection = useAtomValue(activeHomeSection);
  const deviceSize = useAtomValue(deviceScreenSize);
  const appTheme = useAtomValue(theme);

  const [activeTab, setActiveTab] = useState<string | null>(data.tech_stacks?.[0]?.techStack);
  const [hoveredIcon, setHoveredIcon] = useState<TabItem | null>(null);

  const isMobile = useMediaQuery('(max-width:767px)');

  const changeActiveTabHandler = (tab: string) => {
    setHoveredIcon(null);
    setActiveTab(tab);
  };

  useEffect(() => {
    setIsAnimationStarted(activeSection === Section.THIRD);
    if (typeof window !== undefined) {
      setTechStackImageContainerOffsetTop((document?.getElementById('techStackContentId')?.offsetTop || 0) - 180);
    }
  }, [activeSection]);

  const handleColumnOpen = (columnId: number) => {
    const isOpen = !!activeColumns.find(currentId => columnId === currentId);
    if (isOpen) {
      setActiveColumns(prev => prev.filter(currentId => currentId !== columnId));
    } else {
      setActiveColumns([...activeColumns, columnId]);
    }
  };

  useEffect(() => {
    if (activeColumns.length) {
      setIsNoActive(false);
    } else {
      setIsNoActive(true);
    }
  }, [activeColumns]);

  return (
    <section
      // ref={ref}
      id={id}
      className={className}
    >
      <div className={classNames(styles.techStack, {
        [styles.techStack_homepage]: type === 'homepage',
      })}
      >
        <div className='container'>
          <div className={styles.techStack__content}>
            {data.view !== 'horizontal'
            && (
              <div
                className={classNames(homepageStyles.sidebar__visual2, {
                  [homepageStyles.visible]: activeSection === Section.THIRD,
                })}
                style={{
                  top: techStackImageContainerOffsetTop,
                }}
              >
                <Img
                  src='/media/Home/sidebar/techStackLines.webp'
                  alt='lines'
                />
              </div>
            )}
            <header className={styles.techStack__header}>
              <h2 className={classNames('sectionTitle', styles.title)}>
                <AnimatedTitle title={data?.title} />
              </h2>
              {data?.content && (
                <p className={styles.techStack__text}>
                  {data?.content}
                </p>
              )}
            </header>
            {data.view !== 'horizontal'
              ? (
                <div className={styles.content__wrap}>
                  {deviceSize > ScreenSize.TABLET_PORTRAIT && (
                  <div className={classNames(styles.techStack__line, {
                    [styles.techStack__line_animate]: isAnimationStarted,
                  })}
                  />
                  )}
                  <div
                    className={styles.content}
                // ref={contentRef}
                    id={contentId}
                  >
                    {data?.tech_stacks?.map(techStack => (
                      <React.Fragment key={techStack.id}>
                        <TechStackColumn
                          techStack={techStack}
                          handleColumnOpen={handleColumnOpen}
                          isNoActive={isNoActive}
                          isActive={activeColumns.includes(techStack.id)}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              )
              : (isMobile ? (
                <div className={styles.dropdownsList}>
                  {data.tech_stacks.map(stack => (
                    <button
                      type='button'
                      key={stack.id}
                      className={classNames(styles.dropdownsList__item, {
                        [styles.dropdownsList__item_active]: activeTab === stack.techStack,
                      })}
                      onClick={() => setActiveTab((prev) => (prev === stack.techStack ? null : stack.techStack))}
                    >
                      <div className={styles.dropdownsList__item__content}>
                        <p className={classNames(styles.dropdownsList__item__content__title, {
                          [styles.dropdownsList__item__content__title_active]: activeTab === stack.techStack,
                        })}
                        >
                          {stack.techStack}
                        </p>
                        <div
                          className={classNames(styles.dropdownsList__item__content__description, {
                            [styles.dropdownsList__item__content__description_show]: activeTab === stack.techStack,
                          })}
                        >
                          {activeTab && data.tech_stacks.find(({ techStack }) => techStack === activeTab)?.technologies.map(technology => (
                            <div className={styles.dropdownsList__item__content__description__item}>
                              <SvgWithCurrentColorChange
                                icon={technology.image[appTheme]?.url}
                                className={styles.dropdownsList__item__content__description__item__icon}
                              />
                              <p className={styles.dropdownsList__item__content__description__item__title}>{technology.name}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <svg
                        className={classNames(styles.dropdownsList__item__icon, {
                          [styles.dropdownsList__item__icon_active]: activeTab === stack.techStack,
                        })}
                      >
                        <use
                          xlinkHref='/media/angle_down.svg#angleDown'
                          href='/media/angle_down.svg#angleDown'
                        />
                      </svg>
                    </button>
                  ))}
                </div>
              ) : (
                <>
                  <div className={styles.tabsContainer}>
                    {data.tech_stacks.map((stack) => (
                      <button
                        type='button'
                        key={stack.id}
                        className={classNames(styles.tabsContainer__tabButton, {
                          [styles.tabsContainer__tabButton_active]: activeTab === stack.techStack,
                        })}
                        onClick={() => changeActiveTabHandler(stack.techStack)}
                      >
                        {stack.techStack}
                      </button>
                    ))}
                  </div>
                  <div className={styles.contentContainer}>
                    <div className={styles.contentContainer__iconsGrid}>
                      {activeTab && data.tech_stacks.find(({ techStack }) => techStack === activeTab)?.technologies.map((technology) => (
                        <div
                          key={technology.id}
                          className={classNames(styles.contentContainer__iconsGrid__iconItem, {
                            [styles.active]: hoveredIcon?.id === technology.id,
                          })}
                          onMouseEnter={() => setHoveredIcon(technology)}
                        >
                          <SvgWithCurrentColorChange
                            icon={technology.image[appTheme]?.url}
                            className={styles.contentContainer__iconsGrid__iconItem__icon}
                          />
                          <div className={styles.contentContainer__iconsGrid__iconItem__text}>{technology.name}</div>
                        </div>
                      ))}
                    </div>
                    {hoveredIcon && (
                      <div className={styles.contentContainer__descriptionPart}>
                        <h2 className={styles.contentContainer__descriptionPart__title}>{hoveredIcon.name}</h2>
                        <p className={styles.contentContainer__descriptionPart__description}>{hoveredIcon.description}</p>
                      </div>
                    )}
                  </div>
                </>
              )
              )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechStack;
