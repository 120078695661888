'use client';

import { ICasesComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import React from 'react';
import { useAtomValue } from 'jotai';
import AnimatedTitle from '../../../components/AnimatedTitle';
import Button, { ButtonVariants } from '../../../components/Button';
import CaseCard from '../../Cases/CaseCard';
import CaseItem from './CaseItem';
import styles from './CasesBlock.module.scss';
import deviceScreenSize, { ScreenSize } from '../../../store/deviceScreenSize';

const CasesBlock = ({ data, isWithPreviewImages }: { data: ICasesComponent, isWithPreviewImages?: boolean }) => {
  const deviceSize = useAtomValue(deviceScreenSize);

  const isAlternative = data.view === 'alternative';
  const isMobile = deviceSize === ScreenSize.MOBILE;

  return (
    <section className={classnames({
      [styles.cases]: !data.detailed,
      [styles.relatedCases]: data.detailed,
    })}
    >
      <div className='container'>
        <header className={classnames(styles.cases__header, styles[`${data.view}-view`])}>
          <h2 className={classnames('sectionTitle', styles.title)}>
            <AnimatedTitle title={data.title} />
          </h2>
          {!isAlternative && (
            <p className={styles.cases__subtitle}>
              {data.description}
            </p>
          )}
          {isAlternative && !isMobile && (
            <Button
              variant={ButtonVariants.OUTLINED}
              className={styles.linkButton}
              link='/cases'
              icon={(
                <svg className={styles.linkIcon}>
                  <use
                    xlinkHref='/media/icon-nest.svg#iconNest'
                    href='/media/icon-nest.svg#iconNest'
                  />
                </svg>
              )}
            >
              view all cases
            </Button>
          )}
        </header>
        {data.detailed ? (
          <div className={styles.relatedCases__content}>
            {data.cases?.map(caseItem => (
              <CaseCard
                data={caseItem}
                key={caseItem.id}
              />
            ))}
          </div>
        ) : (
          <div className={classnames(styles.content, styles[`${data.view}-view`])}>
            {data.cases?.map(caseItem => (
              <React.Fragment key={caseItem.id}>
                <CaseItem
                  caseItem={caseItem}
                  view={data.view}
                  withPreviewImage={isWithPreviewImages}
                />
              </React.Fragment>
            ))}
          </div>
        )}

        {!isAlternative && (
          <Button
            link='/cases'
            className={styles.viewAllButton}
            variant={ButtonVariants.TRANSPARENT}
            icon={(
              <svg>
                <use
                  xlinkHref='/media/icon-nest.svg#iconNest'
                  href='/media/icon-nest.svg#iconNest'
                />
              </svg>
            )}
            iconSize={{ width: 24, height: 24 }}
          >
            View All
          </Button>
        )}
        {isAlternative && isMobile && (
          <Button
            variant={ButtonVariants.TRANSPARENT}
            className={styles.linkButton}
            link='/cases'
            icon={(
              <svg className={styles.linkIcon}>
                <use
                  xlinkHref='/media/icon-nest.svg#iconNest'
                  href='/media/icon-nest.svg#iconNest'
                />
              </svg>
                )}
          >
            view all cases
          </Button>
        )}
      </div>
    </section>
  );
};

export default CasesBlock;
