'use client';

import React from 'react';
import classNames from 'classnames';
import styles from './IndustrySection.module.scss';
import IndustryCard, { Industry } from './IndustryCard';
import AnimatedTitle from '../../../components/AnimatedTitle';

type IndustrySectionProps = {
  data: {
    title: string;
    list: Industry[];
  };
};

const IndustrySection = ({ data }: IndustrySectionProps) => (
  <section className={classNames(styles.industriesSection, 'container')}>
    <h2 className={styles.industriesSection__title}>
      <AnimatedTitle title={data.title} />
    </h2>
    <div className={styles.industriesSection__list}>
      {data.list.map(industry => (
        <IndustryCard
          industry={industry}
          key={industry.id}
        />
      ))}
    </div>
  </section>
);

export default IndustrySection;
