/* eslint-disable max-len */

'use client';

import React from 'react';
import classNames from 'classnames';
import { CtaReview } from '@sapientpro/sapientpro-data-models';
import { useAtomValue } from 'jotai';
import styles from './TextReviewCardSection.module.scss';
import Img from '../../../components/Img';
import { theme, Theme } from '../../../store/theme';
import Button, { ButtonVariants } from '../../../components/Button';
import AnimatedTitle, { AnimationType } from '../../../components/AnimatedTitle';

type TextReviewCardSectionProps = {
  data: CtaReview;
};

const TextReviewCardSection = ({ data }: TextReviewCardSectionProps) => {
  const appTheme = useAtomValue<Theme>(theme);

  const handleJoinUsClick = () => {
    const element = document.getElementById('contact-form');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className={classNames(styles.textReviewSection, 'container')}>
      <div className={styles.textReviewCard}>
        <div className={styles.container}>
          <div className={styles.avatar}>
            <div className={styles.avatarImage}>
              <Img
                src={data.review.author.avatar?.url}
                alt={data.review.author.avatar?.alternativeText || 'author avatar'}
                className={styles.image}
                key={appTheme}
              />
            </div>
            <div className={styles.nameAndPosition}>
              <p className={styles.name}>{data.review.author.name}</p>
              <p className={styles.position}>{data.review.author.position}</p>
            </div>
          </div>

          <div className={styles.review}>
            <h3 className={classNames('sectionTitle', styles.title)}>
              <AnimatedTitle
                title={`”${data.review.textFeedback}”`}
                animationType={AnimationType.MULTIPLE}
              />
            </h3>
            <p className={styles.text}>
              {data.title}
            </p>
          </div>
        </div>
        <Button
          className={styles.button}
          variant={ButtonVariants.PRIMARY}
          icon={(
            <svg>
              <use
                xlinkHref='/media/arrow-right-bold.svg#arrowRight'
                href='/media/arrow-right-bold.svg#arrowRight'
              />
            </svg>
        )}
          iconPosition='right'
          iconSize={{ width: 24, height: 24 }}
          onClick={handleJoinUsClick}
        >
          {data.ctaButton}
        </Button>
        <div className={styles.mapDecoration}>
          <img
            src='/media/text-review-card-map-decoration-bg.svg'
            alt='Map decoration'
          />
        </div>
      </div>
    </section>
  );
};

export default TextReviewCardSection;
