import classnames from 'classnames';
import React from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { useAtomValue } from 'jotai';
import styles from './Slider.module.scss';
import Img from '../Img';
import { theme } from '../../store/theme';
import deviceScreenSize, { ScreenSize } from '../../store/deviceScreenSize';

const splideArrowsClasses = {
  arrowsWrapper: 'splide__arrows',
  prevArrow: 'splide__arrow splide__arrow--prev',
  nextArrow: 'splide__arrow splide__arrow--next',
};

const splidePaginationClasses = {
  paginationWrapper: 'splide__pagination',
  paginationPage: 'splide__pagination__page',
};

export enum SliderVariants {
  SLIDE = 'slide',
}

type SlideProps = {
  id: string | number;
  content: React.ReactNode;
};

type SliderOptionsProps = Partial<{
  slideVariant: SliderVariants;
  slideFocus: number;
  slideStart: number;
  slidesPerPage: number;
  showOverflow: boolean;
  perPage: number;
  slidesGap: number;
  showPagination: boolean;
  showArrows: boolean;
}>;

type SliderProps = {
  slides: SlideProps[];
  options?: SliderOptionsProps
};

const Slider = ({
  slides,
  options,
}: SliderProps) => {
  const appTheme = useAtomValue(theme);
  const [activeSlideIndex, setActiveSlideIndex] = React.useState<number>(0);
  const deviceSize = useAtomValue(deviceScreenSize);

  const isMobile = deviceSize === ScreenSize.MOBILE;

  return (
    <Splide
      hasTrack={false}
      options={{
        type: options?.slideVariant || SliderVariants.SLIDE,
        focus: options?.slideFocus,
        start: options?.slideStart,
        perPage: options?.slidesPerPage,
        gap: options?.slidesGap,
        pagination: options?.showPagination,
        classes: {
          pagination: `${splidePaginationClasses.paginationWrapper} ${styles.pagination}`,
          page: `${splidePaginationClasses.paginationPage} ${styles.pagination__item}`,
        },
        arrows: !isMobile,
        autoWidth: false,
      }}
      className={styles.slider}
      onMove={e => setActiveSlideIndex(e.index)}
    >
      <SplideTrack
        className={classnames(styles.track, {
          [styles.track_overflow]: options?.showOverflow,
        })}
      >
        {slides.map((slide, index) => (
          <SplideSlide
            key={slide.id}
            className={classnames(styles.slide, {
              [styles.slide_active]: index === activeSlideIndex,
            })}
          >
            {slide.content}
          </SplideSlide>
        ))}
      </SplideTrack>
      {options?.showArrows && !isMobile && (
        <div className={classnames(splideArrowsClasses.arrowsWrapper, styles.navigation)}>
          <button
            type='button'
            className={classnames(splideArrowsClasses.prevArrow, styles.navigation__arrow, styles.navigation__arrow_prev)}
          >
            <Img
              src={`/media/slider-arrow-right-${appTheme}.svg`}
              alt='slider arrow left'
            />
          </button>
          <button
            type='button'
            className={classnames(splideArrowsClasses.nextArrow, styles.navigation__arrow, styles.navigation__arrow_next)}
          >
            <Img
              src={`/media/slider-arrow-right-${appTheme}.svg`}
              alt='slider arrow right'
            />
          </button>
        </div>
      )}
    </Splide>
  );
};

export default Slider;
