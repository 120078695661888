'use client';

import SvgWithCurrentColorChange from '../../../components/SvgWithCurrentColorChange';
import styles from './DevelopmentServicesCard.module.scss';

type DevelopmentServicesCardProps = {
  icon: string,
  title: string,
  description: string,
};

export default function DevelopmentServicesCard({ icon, title, description }: DevelopmentServicesCardProps) {
  return (
    <div className={styles.developmentServicesCard}>
      <div className={styles.developmentServicesCard__imageWrapper}>
        <SvgWithCurrentColorChange
          icon={icon}
          className={styles.developmentServicesCard__imageWrapperIcon}
        />
      </div>
      <div className={styles.developmentServicesCard__title}>{title}</div>
      <div className={styles.developmentServicesCard__description}>{description}</div>
    </div>
  );
}
