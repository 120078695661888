import classnames from 'classnames';

const Paragraph = ({ value, className }: { value: string, className?: string }) => (
  <div
    className={classnames(className)}
    dangerouslySetInnerHTML={{ __html: value }}
  />
);

export default Paragraph;
