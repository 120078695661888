import { UtilTitleSimpleTextImage } from '@sapientpro/sapientpro-data-models';
import classNames from 'classnames';
import styles from './CaseSlider.module.scss';
import Img from '../../../../components/Img';

export type SliderItem = {
  id: number;
  content: UtilTitleSimpleTextImage[];
};

const CaseSliderItem = ({ data, doubled }: { data: SliderItem; doubled?: boolean; }) => (
  <div className={classNames(styles.sliderItem, {
    [styles.doubledView]: doubled,
  })}
  >
    {
    data.content.map((item) => (
      <div
        key={item.id}
        className={styles.itemContent}
      >
        <div className={styles.sliderItemHead}>
          <h5 className={styles.itemTitle}>{item.title}</h5>
          <p className={styles.itemText}>{item.description}</p>
        </div>
        <Img
          src={item.image.url}
          width={item.image.width}
          height={item.image.height}
          alt={item.image.alternativeText || 'content image'}
          className={styles.itemImage}
        />
      </div>
    ))
  }
  </div>
);

export default CaseSliderItem;
