'use client';

import { IFile, ReviewListView } from '@sapientpro/sapientpro-data-models';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import styles from './Video.module.scss';
import CustomCursorWrapper from '../CustomCursor/CustomCursorWrapper';
import Img from '../Img';

const formatDuration = (durationString: string) => {
  let result = durationString;

  result = result.replace(/PT|S/g, '');
  result = result.replace(/H|M/g, ':');
  result = result.replace(/\b(\d)\b/g, '0$1');

  if (result.length === 2) result = `00:${result}`;

  return result;
};

type VideoDataResponse = {
  etag: string;
  items: {
    id: string;
    kind: string;
    contentDetails: {
      duration: string;
      caption: string;
      definition: string;
      licensedContent: boolean;
      projection: string;
    },
  }[];
  kind: string;
};

export type VideoDataProps = {
  title: string,
  clip: IFile,
  preview: {
    url: string,
    alternativeText: string,
  },
};

type VideoProps = {
  video: VideoDataProps,
  view: ReviewListView,
  activeIndex: number,
  className?: string,
};

const Video = ({
  className, video, view, activeIndex,
}: VideoProps) => {
  const videoRef = useRef<HTMLIFrameElement>(null);
  const [videoDuration, setVideoDuration] = useState<string>('');
  const [showVideo, setShowVideo] = useState<boolean>(false);

  function controlVideo(controlFunction: string) {
    if (videoRef) {
      videoRef.current?.contentWindow?.postMessage(
        `{"event":"command","func":"${controlFunction}","args":""}`,
        '*',
      );
    }
  }

  useEffect(() => {
    const getDuration = async (link: string) => {
      const apiKey = process.env.NEXT_PUBLIC_YOUTUBE_API_KEY;
      const videoId = link.split('embed/')?.[1]?.split('?')?.[0];
      const url = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=contentDetails&key=${apiKey}`;

      if (!apiKey || !videoId) return;
      try {
        const response = await fetch(url, {
          method: 'get',
        });
        const data = await response.json();

        const duration = (data as VideoDataResponse)?.items?.[0]?.contentDetails?.duration || '';
        const formattedDuration = formatDuration(duration);

        setVideoDuration(formattedDuration);
      } catch (error) {
        console.error('getDuration error', error);
      }
    };

    if (video.clip.url) getDuration(video.clip.url);
  }, [video]);

  useEffect(() => {
    controlVideo('stopVideo');
    setShowVideo(false);
  }, [activeIndex]);

  const showPreview = !showVideo && video.preview.url;

  return (
    <div
      className={classNames(styles.videoWrapper, styles[`${view}-view`], className)}
    >
      {showPreview && (
        <button
          type='button'
          onClick={() => {
            !!video.clip.url && setShowVideo(true);
          }}
          className={styles.previewButton}
        >
          <CustomCursorWrapper
            cursorText='Play'
            view='light'
            className={styles.previewWrapper}
            disabled={!video.clip.url}
          >
            <Img
              src={video?.preview?.url}
              alt={video?.preview?.alternativeText || 'review video preview'}
              className={styles.preview}
            />
            {!!video.clip.url && (
              <div className={styles.previewOverlay}>
                <svg className={styles.playIcon}>
                  <use
                    href='media/play-icon.svg#play-icon'
                    xlinkHref='media/play-icon.svg#play-icon'
                  />
                </svg>

                {!!videoDuration && <span className={styles.videoDuration}>{videoDuration}</span>}
              </div>
            )}
          </CustomCursorWrapper>
        </button>
      )}
      {showVideo && (
        <div
          className={styles.video}
        >
          <iframe
            ref={videoRef}
            width='950'
            height='534'
            src={`${video.clip.url}?rel=0&enablejsapi=1`}
            title={video.title}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
            onLoad={() => controlVideo('playVideo')}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(Video);
