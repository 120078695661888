'use client';

import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { UtilTitleSimpleTextImage } from '@sapientpro/sapientpro-data-models';
import styles from './SideRevealingPoints.module.scss';
import { Theme, theme } from '../../../store/theme';

export default function SideRevealingPoints({
  points,
}: {
  points: UtilTitleSimpleTextImage[];
}) {
  const [isActive, setIsActive] = useState<number | null>(null);
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  const appTheme = useAtomValue(theme);

  useEffect(() => {
    const checkSize = () => {
      const isDesktopView = window.innerWidth >= 1024;
      setIsDesktop(isDesktopView);
      if (isDesktopView) {
        setIsActive(1);
      } else {
        setIsActive(null);
      }
    };

    checkSize();
    window.addEventListener('resize', checkSize);

    return () => {
      window.removeEventListener('resize', checkSize);
    };
  }, []);

  const handleClick = (id: number) => {
    if (isDesktop) {
      if (isActive !== id) {
        setIsActive(id);
      }
      return;
    }
    setIsActive(isActive === id ? null : id);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftColumn}>
        {points.map((point) => (
          <button
            key={point.id}
            type='button'
            onClick={() => handleClick(point.id)}
            className={classNames(styles.point, {
              [styles.active]: isActive === point.id,
            })}
          >
            <div className={styles.point__content}>
              <p className={classNames(styles.point__content__title)}>{point.title}</p>
              {!isDesktop && isActive === point.id && (
                <p className={styles.point__content__description}>{point.description}</p>
              )}
              {!isDesktop && isActive === point.id && points[isActive - 1]?.image && (
                <img
                  className={styles.point__content__image}
                  src={points[isActive - 1]?.image.url}
                  alt={points[isActive - 1]?.image.alternativeText ?? points[isActive - 1]?.title}
                />
              )}
            </div>
            <svg className={styles.point__icon}>
              <use
                xlinkHref='/media/arrow-right-bold.svg#arrowRight'
                href='/media/arrow-right-bold.svg#arrowRight'
              />
            </svg>
          </button>
        ))}
      </div>
      {isDesktop && isActive !== null && (
        <div className={styles.rightColumn}>
          <h2>{points[isActive - 1]?.title}</h2>
          <p className={styles.rightColumn__description}>{points[isActive - 1]?.description}</p>
          {points[isActive - 1]?.image && (
            <img
              className={styles.rightColumn__image}
              src={appTheme === Theme.LIGHT ? points[isActive - 1]?.image.url : points[isActive - 1]?.darkImage?.url}
              alt={(
                appTheme === Theme.LIGHT
                  ? points[isActive - 1]?.image.alternativeText
                  : points[isActive - 1]?.darkImage?.alternativeText
              ) ?? points[isActive - 1]?.title}
            />
          )}
        </div>
      )}
    </div>
  );
}
