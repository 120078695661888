'use client';

import Video, { VideoDataProps } from '../../../components/Video/Video';
import styles from './CustomProcessCard.module.scss';

type CustomProcessCardProps = {
  stepNumber: number,
  title: string,
  description: string,
  video: VideoDataProps,
};

export default function CustomProcessCard({
  stepNumber, title, description, video,
}: CustomProcessCardProps) {
  return (
    <div className={styles.customProcessCard}>
      <div className={styles.customProcessCard__infoPart}>
        <p className={styles.customProcessCard__infoPart__step}>
          Step
          {' '}
          {stepNumber}
        </p>
        <p className={styles.customProcessCard__infoPart__title}>{title}</p>
        <p className={styles.customProcessCard__infoPart__description}>{description}</p>
      </div>
      <div className={styles.customProcessCard__contentPart}>
        <Video
          video={video}
          view='default'
          activeIndex={0}
        />
      </div>
    </div>
  );
}
