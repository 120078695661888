'use client';

import { CustomTeam } from '@sapientpro/sapientpro-data-models';
import classNames from 'classnames';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import styles from './CustomTeam.module.scss';
import AnimatedTitle from '../AnimatedTitle';
import TeamMember from '../../page-components/Subservice/TeamMember';
import { useSectionOffsetScroll } from '../../useSectionOffsetScroll';
import deviceScreenSize, { ScreenSize } from '../../store/deviceScreenSize';
import Img from '../Img';
import { theme } from '../../store/theme';

const MAX_PHOTO_MARGIN = 64;
const VIEWPORT_PADDING = 300;

const CustomTeamSection = ({ data }: { data: CustomTeam }) => {
  const appTheme = useAtomValue(theme);
  const screenSize = useAtomValue(deviceScreenSize);
  const [nextSlideIndex, setNextSlideIndex] = useState<number>(0);
  const [prevSlideIndex, setPrevSlideIndex] = useState<number>(0);
  const sliderOffset = useSectionOffsetScroll('custom-team-slider');
  const sliderRef = useRef<Splide>(null);
  const memberMargin = sliderOffset - VIEWPORT_PADDING >= MAX_PHOTO_MARGIN ? MAX_PHOTO_MARGIN : sliderOffset - VIEWPORT_PADDING;

  const setNextIndex = () => {
    const windowWidth = window.outerWidth;
    const slides = (sliderRef.current as any)?.slides;

    const nextIndex = (slides as HTMLLIElement[]).findIndex((item, index) => {
      const rect = item.getBoundingClientRect();
      const itemWidth = rect.width;
      const isOutOfView = rect.x + itemWidth > windowWidth;
      const isAfterCurrent = (sliderRef.current?.splide?.index || 0) < index;

      return isOutOfView && isAfterCurrent;
    });

    setNextSlideIndex(nextIndex);
  };

  const setPrevIndex = () => {
    const slides = (sliderRef.current as any)?.slides;

    const prevIndex = (slides as HTMLLIElement[]).reduce((result, item, index) => {
      const rect = item.getBoundingClientRect();
      const isOutOfView = rect.x < 0;
      const isBeforeCurrent = (sliderRef.current?.splide?.index || 0) > index;

      if (isOutOfView && isBeforeCurrent && result < index) return index;
      return result;
    }, -1);

    setPrevSlideIndex(prevIndex);
  };

  const setNavigationIndexes = () => {
    setNextIndex();
    setPrevIndex();
  };

  useEffect(() => {
    window.addEventListener('resize', setNavigationIndexes);
    setNavigationIndexes();

    return () => window.removeEventListener('resize', setNavigationIndexes);
  }, []);

  return (
    <section className={styles.caseTeam}>
      <div className={classNames(styles.caseTeamHead, 'container')}>
        <h2 className={styles.caseTeamTitle}><AnimatedTitle title={data.title} /></h2>
        <p className={styles.caseTeamDescription}>{data.description}</p>
      </div>
      <Splide
        id='custom-team-slider'
        hasTrack={false}
        onActive={() => setNavigationIndexes()}
        options={{
          focus: 'center',
          pagination: false,
          autoWidth: true,
          width: '100%',
          breakpoints: {
            1279: {
              padding: 16,
            },
            767: {
              padding: 24,
            },
          },
        }}
        className={styles.membersSlider}
        ref={sliderRef}
      >
        <SplideTrack className={styles.membersTrack}>
          {data.team_members.map((member, index) => {
            const isEven = index % 2 === 0;
            const isMobile = screenSize < ScreenSize.TABLET_PORTRAIT;
            const applyMargin = isEven && sliderOffset > VIEWPORT_PADDING && !isMobile;
            const style = applyMargin ? { marginTop: `${memberMargin}px` } : {};

            return (
              <SplideSlide style={style}>
                <TeamMember
                  key={member.id}
                  name={member.name}
                  position={member.position}
                  image={member.image}
                  sliderView
                />
              </SplideSlide>
            );
          })}
        </SplideTrack>
        <div className={classNames('splide__arrows', styles.navigation)}>
          <button
            type='button'
            className={classNames('splide__arrow splide__arrow--prev', styles.arrow, styles.arrow_prev)}
            onClick={() => {
              if (prevSlideIndex !== -1) sliderRef.current?.go(prevSlideIndex);
              else sliderRef.current?.go('<');
            }}
            disabled={prevSlideIndex === -1}
          >
            <Img
              src={`/media/slider-arrow-right-${appTheme}.svg`}
              alt='slider arrow left'
            />
          </button>
          <button
            type='button'
            className={classNames('splide__arrow splide__arrow--next', styles.arrow, styles.arrow_next)}
            onClick={() => {
              if (nextSlideIndex !== -1) sliderRef.current?.go(nextSlideIndex);
              else sliderRef.current?.go('>');
            }}
            disabled={nextSlideIndex === -1}
          >
            <Img
              src={`/media/slider-arrow-right-${appTheme}.svg`}
              alt='slider arrow right'
            />
          </button>
        </div>
      </Splide>
    </section>
  );
};

export default CustomTeamSection;
