'use client';

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { useMemo, useRef, useState } from 'react';
import { CasesPageSlider } from '@sapientpro/sapientpro-data-models';
import styles from './CaseSlider.module.scss';
import CaseSliderItem, { SliderItem } from './CaseSliderItem';
import Img from '../../../../components/Img';
import { theme } from '../../../../store/theme';

const CaseSlider = ({ data }: { data: CasesPageSlider }) => {
  const appTheme = useAtomValue(theme);

  const centeredView = data.position === 'centered';
  const startIndex = centeredView && !data.pairUp ? Math.floor(data.slides.length / 2) : 0;

  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(startIndex);
  const sliderRef = useRef<Splide>(null);
  const slides: SliderItem[] = useMemo(() => {
    if (data.pairUp) {
      const newSlides = data.slides.reduce((result, slide, index) => {
        const isEven = (index + 1) % 2 === 0;

        if (isEven) {
          const lastIndex = result.length - 1;
          const lastItem: SliderItem = result[lastIndex];
          result[lastIndex].content = [...lastItem.content, slide];

          return result;
        } else {
          return [...result, { id: slide.id, content: [slide] }];
        }
      }, [] as SliderItem[]);

      return newSlides;
    } else {
      const newSlides = data.slides.map((slide) => ({ id: slide.id, content: [slide] }));

      return newSlides;
    }
  }, [data.slides, data.pairUp]);

  const paginationItems = useMemo(() => {
    const items = new Array(slides.length).fill(0).map((_, index) => index);

    return items;
  }, [slides, data.slides, data.pairUp]);

  return (
    <section className={classNames(styles.caseSliderSection, {
      [styles.doubledView]: data.pairUp,
    })}
    >
      <h2 className={classNames(styles.caseSliderTitle, 'container')}>{data.title}</h2>
      <Splide
        hasTrack={false}
        options={{
          perMove: 1,
          autoWidth: true,
          focus: 'center',
          arrows: false,
          pagination: false,
          start: startIndex,
        }}
        onMove={(_, index) => setActiveSlideIndex(index)}
        ref={sliderRef}
      >
        <SplideTrack className={classNames(styles.sliderTrack, {
          [styles.withSideShadow]: !data.pairUp,
        })}
        >
          {
        slides.map(slide => (
          <SplideSlide
            key={slide.id}
            className={classNames(styles.slide, {
              [styles.centered]: centeredView,
              [styles.doubled]: data.pairUp,
            })}
          >
            <CaseSliderItem
              data={slide}
              doubled={data.pairUp}
            />
          </SplideSlide>
        ))
        }
        </SplideTrack>
      </Splide>
      <div className='container'>
        <div className={classNames(styles.navigation, 'splide__arrows', {
          [styles.centeredView]: centeredView,
        })}
        >
          <button
            onClick={() => {
              sliderRef.current?.go('-1');
            }}
            type='button'
            className={classNames('splide__arrow splide__arrow--prev', styles.arrow, styles.arrow_prev)}
          >
            <Img
              src={`/media/slider-arrow-right-${appTheme}.svg`}
              alt='slider arrow right'
            />
          </button>
          <div className={styles.pagination}>
            {
          paginationItems.map(item => (
            <button
              key={item}
              type='button'
              className={classNames(styles.pagination__item, {
                [styles.active]: activeSlideIndex === item,
              })}
              onClick={() => {
                sliderRef.current?.go(item);
                setActiveSlideIndex(item);
              }}
            />
          ))
          }
          </div>
          <button
            onClick={() => {
              sliderRef.current?.go('+1');
            }}
            type='button'
            className={classNames('splide__arrow splide__arrow--next', styles.arrow, styles.arrow_next)}
          >
            <Img
              src={`/media/slider-arrow-right-${appTheme}.svg`}
              alt='slider arrow right'
            />
          </button>
        </div>
      </div>
    </section>
  );
};

export default CaseSlider;
