'use client';

import {
  CaseServiceComponent,
} from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import React from 'react';
import AnimatedTitle from '../../../components/AnimatedTitle';
import Button, { ButtonVariants } from '../../../components/Button';
import CaseCard from '../../Cases/CaseCard';
import CaseItem from './CaseItem';
import styles from './CasesBlock.module.scss';

const DynamicCasesBlock = ({ data }: { data: CaseServiceComponent }) => (
  <section
    className={classnames({
      [styles.cases]: !data.detailed,
      [styles.relatedCases]: data.detailed,
    })}
  >
    <div className='container'>
      <header className={styles.cases__header}>
        <h2 className={classnames('sectionTitle', styles.title)}>
          <AnimatedTitle title={data.title} />
        </h2>
        <p className={styles.cases__subtitle}>{data.description}</p>
      </header>
      {data.detailed ? (
        <div className={styles.relatedCases__content}>
          {data?.cases?.map((caseItem) => (
            <CaseCard
              data={caseItem}
              key={caseItem.id}
            />
          ))}
        </div>
      ) : (
        <div className={styles.content}>
          {data?.cases?.map((caseItem) => (
            <React.Fragment key={caseItem.id}>
              <CaseItem caseItem={caseItem} />
            </React.Fragment>
          ))}
        </div>
      )}

      <Button
        link='/cases'
        className={styles.viewAllButton}
        variant={ButtonVariants.TRANSPARENT}
        icon={(
          <svg>
            <use
              xlinkHref='/media/icon-nest.svg#iconNest'
              href='/media/icon-nest.svg#iconNest'
            />
          </svg>
          )}
        iconSize={{ width: 24, height: 24 }}
      >
        View All
      </Button>
    </div>
  </section>
);
export default DynamicCasesBlock;
