import classNames from 'classnames';
import Img from '../../components/Img';
import styles from './Subservice.module.scss';

type TeamMemberProps = {
  image: {
    url: string,
    alternativeText: string | null,
  },
  name: string,
  position?: string,
  sliderView?: boolean;
};
const TeamMember = ({
  image, name, position, sliderView,
}: TeamMemberProps) => (
  <div className={classNames(styles.teamMember, {
    [styles.sliderView]: sliderView,
  })}
  >
    <div className={classNames(styles.teamMember__photo, {
      [styles.sliderView]: sliderView,
    })}
    >
      <Img
        src={image.url}
        alt={image.alternativeText || name}
      />
    </div>
    <p className={classNames(styles.teamMember__name, {
      [styles.sliderView]: sliderView,
    })}
    >
      {name}
    </p>
    {position && (
    <p className={classNames(styles.teamMember__position, {
      [styles.sliderView]: sliderView,
    })}
    >
      {position}
    </p>
    )}
  </div>
);

export default TeamMember;
