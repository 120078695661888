'use client';

import styles from './ReasonsToOutsourceItem.module.scss';

type ReasonsToOutsourceItemProps = {
  number: string,
  title: string,
  description: string,
};

export default function ReasonsToOutsourceItem({ number, title, description }: ReasonsToOutsourceItemProps) {
  return (
    <div className={styles.reasonsToOutsourceItem}>
      <div className={styles.reasonsToOutsourceItem__numberAndTitlePart}>
        <p className={styles.reasonsToOutsourceItem__numberAndTitlePart__number}>{number}</p>
        <p className={styles.reasonsToOutsourceItem__numberAndTitlePart__title}>{title}</p>
      </div>
      <p className={styles.reasonsToOutsourceItem__description}>{description}</p>
    </div>
  );
}
