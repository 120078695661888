import React, { useEffect, useState } from 'react';

interface SvgWithCurrentColorChangeProps {
  icon: string,
  className?: string,
}

const SvgWithCurrentColorChange = ({
  icon, className,
}: SvgWithCurrentColorChangeProps) => {
  const [downloadedIcon, setDownloadedIcon] = useState<string | null>(null);

  useEffect(() => {
    const fetchSvg = async () => {
      try {
        if (!icon) return;

        const response = await fetch(icon);
        const svgText = await response.text();
        setDownloadedIcon(svgText);
      } catch (error) {
        console.error('Error fetching SVG:', error);
      }
    };

    fetchSvg();
  }, []);

  return downloadedIcon ? (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: downloadedIcon }}
    />
  ) : null;
};

export default SvgWithCurrentColorChange;
